@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'primeicons/primeicons.css';

html,
body,
:root {
  font-family: 'Roboto', sans-serif;
}

@font-face {
  font-family: 'roboto';
  src: url('assets/fonts/Roboto-Medium.ttf');
}

@font-face {
  font-family: 'roboto-regular';
  src: url('assets/fonts/Roboto-Regular.ttf');
}

@layer base {
  /* Remove scrollbar */
  .remove-scrollbar::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    border-radius: 0px;
  }

  .remove-scrollbar::-webkit-scrollbar-track {
    background: transparent;
  }

  .remove-scrollbar::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 0px;
  }

  .remove-scrollbar::-webkit-scrollbar-thumb:hover {
    /* background: #1e2238; */
    background: transparent;
  }
}

@layer base {
  :root {
    /* fonts */
    --: Roboto;
    /* font sizes */
    --font-size-base: 16px;
    --font-size-sm: 14px;
    --font-size-xs: 12px;
    --font-size-6xl: 25px;

    /* Colors */
    --color-white: #fff;
    --color-polygon-100: #d3f1fb;
    --color-royalblue-100: #5a82fd;
    --color-royalblue-200: #3159d5;
    --color-gray-100: #fdfdfd;
    --color-gray-200: #191e20;
    --color-gainsboro: #dbdbdb;
    --color-black: #000;

    --color-whitesmoke-500: #e9ecef;
    --texticon-primary-black: #0e121a;

    /* Gaps */
    --gap-3xs: 10px;

    /* Paddings */
    --padding-base: 16px;
    --padding-9xl: 28px;

    /* Border radiuses */
    --br-3xs: 10px;
    --br-mini: 15px;
  }
  .activeLink {
    @apply bg-[#ECF1FF] dark:bg-slate-700;
    font-weight: bold !important;
  }

  .legalBgColor {
    @apply bg-[#32447F];
  }

  .activeProfileLink {
    @apply bg-[#F6F6F6];
    font-weight: bold !important;
    padding: 4px;
  }

  .input {
    @apply border-2 rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none;
  }

  .checkBox {
    @apply w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600;
  }

  .select {
    @apply bg-gray-50 border border-gray-300 text-font-color text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
  }

  .textArea {
    @apply block p-2.5 w-full text-sm rounded-lg border-2 border-gray-200 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-200 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500;
  }

  .radio {
    @apply w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600;
  }

  .label {
    @apply text-[14px] font-semibold  block text-gray-600 mb-2;
  }
  .header-animation {
    @apply animate-fade-right animate-once animate-duration-1000 animate-delay-100;
  }
}

.p-divider-content {
  padding: 0px !important;
}
.p-divider.p-divider-horizontal:before {
  border-top: 1.7px solid #dbdbdb !important;
}

.tabview-custom .p-tabview-panels {
  border: none !important;
}
// *********** primeng component

input {
  font-size: 0.875rem;
  color: black;
}

.p-autocomplete-input,
.p-autocomplete {
  width: 100% !important;
  background-color: transparent;
  outline: none;
  box-shadow: none !important;
}

.p-inputmask,
.p-calendar {
  width: 100%;
  padding: 0.5rem 0.5rem;
  border-radius: 6px;
  width: 100%;
  border: 1px solid #ced4da;
  font-size: 0.875rem;
}

.ssn .p-inputtext:enabled:focus {
  outline: 0 none !important;
  outline-offset: 0;
  box-shadow: 0 0 0 0.1rem transparent !important;
  border-color: #e5e7eb;
  font-family: 'Roboto', sans-serif;
}

.p-divider {
  border: none;
}

// *********************
.p-inputtext {
  box-shadow: none;
  font-family: 'Roboto', sans-serif;
}
.fqinut input {
  width: 500px;
}
.p-dropdown {
  border: none !important;
  background-color: transparent !important;
  width: 100%;
  font-family: 'Roboto', sans-serif;
}

.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
  font-family: 'Roboto', sans-serif;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.p-hidden-accessible {
  display: none;
}

.p-fileupload-buttonbar {
  display: flex;
  width: 80%;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  font-family: 'Roboto', sans-serif !important;
}

.p-menu {
  z-index: 1000 !important;
  top: 25px !important;
  left: 0px !important;
  max-width: 150px;
}

.p-fileupload-buttonbar {
  margin-bottom: 10px !important;
}

.p-fileupload-content {
  padding: 0px !important;
}

p-dropdown {
  border: 2px solid #e5e7eb;
  padding: 8px;
  border-radius: 5px;
}
.p-dropdown-label {
  margin-left: -10px !important;
}

.pdropdownControl .p-component {
  width: 100%;
  font-family: 'Roboto', sans-serif !important;
}

.pdropdownControl .p-component span {
  text-wrap: wrap;
  padding: 10px;
  font-size: 14px;
  font-family: 'Roboto', sans-serif !important;
}

.tabview-custom .p-tabview-nav > li {
  margin: 0 2px;
  background: #f1f1f1;
}

.tabview-custom .p-tabview-nav > li > a {
  background: #f1f1f1;
  padding: 11px 25px 7px 25px;
}

.tabview-custom .p-highlight {
  border: 1px solid #ababab;
  border-top: 6px solid #5a82fd;
}

.tabview-custom .p-highlight > a,
.tabview-custom .p-highlight {
  background-color: #fff !important;
}

.tabview-custom .p-tabview-panels {
  border: 1px solid #ababab;
}

.additionalLocations {
  border: 1px solid #ababab;
  border-radius: 10px;
  padding: 16px 20px;
}

p-inputnumber {
  width: 100%;
  border: 2px solid #e5e7eb;
  padding: 10px;
  border-radius: 5px;
}

p-inputnumber .p-inputwrapper span {
  width: 100% !important;
}

.p-inputwrapper .p-inputtext:enabled:focus {
  outline: none !important;
  outline-offset: 0;
  border-color: #fff !important;
}

@media only screen and (min-width: 1400px) {
  .homeMaxWidth {
    width: 80%;
    margin: 0 auto;
    max-width: 1320px;
  }
}

textarea:disabled {
  background: #fafafa;
}

/* For large screens (desktops) */
@media only screen and (min-width: 1020px) {
  input[inputmode='decimal'] {
    width: 100%;
    max-width: 400px;
    outline: none;
    min-width: 300px; /* Ensures readability */
  }
}

/* For medium screens (tablets and smaller desktops) */
@media only screen and (min-width: 720px) and (max-width: 1020px) {
  input[inputmode='decimal'] {
    width: 80%; /* Responsive width for medium screens */
    max-width: 300px;
    min-width: 250px; /* Ensures readability */
  }
}

/* For smaller screens (mobile devices and small tablets) */
@media only screen and (max-width: 719px) {
  input[inputmode='decimal'] {
    width: 100%;
    max-width: 100%; /* Full container width */
    min-width: 240px; /* Prevents text from being too small */
  }

  .ownerMenu > .p-menu {
    left: -90px !important; /* Keeps other elements responsive */
  }
}

.withoutgrouping input[inputmode='decimal'] {
  outline: none;
  flex: 1;
}

input[inputmode='decimal']:focus {
  box-shadow: none !important;
}

.autocompleteControl .p-component {
  width: 100%;
  font-family: 'Roboto', sans-serif !important;
}

.autocompleteControl .p-component span {
  text-wrap: wrap;
  font-family: 'Roboto', sans-serif !important;
}
.p-component {
  text-wrap: wrap;
  font-family: 'Roboto', sans-serif !important;
}

@media only screen and (min-width: 900px) and (max-width: 1050px) {
  .tabview-custom .p-tabview-nav > li > a {
    padding: 11px 38px 7px 38px;
  }
}

@media only screen and (min-width: 420px) and (max-width: 450px) {
  .tabview-custom .p-tabview-nav > li > a {
    padding: 11px 15px 7px 15px;
  }
}

@media only screen and (min-width: 361px) and (max-width: 420px) {
  .tabview-custom .p-tabview-nav > li > a {
    padding: 11px 6px 7px 6px;
  }
}

@media only screen and (min-width: 300px) and (max-width: 360px) {
  .tabview-custom .p-tabview-nav > li > a {
    padding: 6px 4px 6px 4px;
  }
}

.dateFormed p-calendar {
  .p-inputtext {
    display: none !important;
  }
  button.ui-datepicker-trigger.ui-calendar-button {
    border-radius: 4px !important;
  }
  .p-datepicker {
    z-index: 1002 !important;
    top: 50px !important;
    left: -300px !important;
  }
}

ol.c {
  list-style-type: lower-roman;
}

ol.ownership {
  list-style-type: lower-alpha;
}

ol.lower-alpha {
  list-style-type: lower-alpha;
}

ol.disc {
  list-style-type: disc;
}

ol.circle {
  list-style-type: circle;
}

html,
body,
:root {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.custom-list {
  list-style: none;
  counter-reset: item;
}

.custom-list li {
  counter-increment: item;
  margin-bottom: 5px;
}

.custom-list li:before {
  margin-right: 10px;
  content: counter(item);
  color: rgb(43, 42, 42);
  width: 1.2em;
  text-align: center;
  display: inline-block;
  align-items: center;
  justify-content: center;
}

.custom-squre {
  list-style-type: circle;
  padding-left: 20px; /* Adjust the left padding to accommodate the bullets */
}

.custom-squre li {
  margin-bottom: 5px;
  position: relative;
}

.custom-squre li:before {
  content: '';
  display: none; /* Hide the counter if not needed */
}
.bg-lavender {
  background-color: rgba(226, 231, 251, 0.5);
}
@media screen and (max-width: 450px) {
  .mq450\:text-lgi {
    font-size: 19px;
  }
}
